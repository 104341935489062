@use "../../../../scss/abstracts/" as *;
.lang {
  position: relative;
}
.lanBox {
  @include flex();
  margin: 0 0.8rem;
  cursor: pointer;
}
.lang_slc {
  padding: 0.3rem;
}
.lang_menu {
  width: 110px;
  font-size: 12px;
  background-color: var(--card-bgc);
  position: absolute;
  top: 160%;
  opacity: 0;
  user-select: none;
  padding: 0.5rem;
  box-shadow: 1px 0 8px 0 rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease-in-out;
  
  & > *:not(:last-child) {
    margin-bottom: 0.5rem;
  }
  & div:hover {
    font-weight: bold;
    cursor: pointer;
  }
  &.show {
    top: 120%;
    opacity: 1;
    user-select: auto;
  }
}
