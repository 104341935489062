
.notification-container {
	font-size: 14px;
	font: normal normal bold 14px/19px Noto Sans, sans-serif;
	box-sizing: border-box;
	position: fixed;
	z-index: 999999
}

.top-right {
	top: 12px;
	right: 12px;
	transition: transform .6s ease-in-out;
	animation: toast-in-right .7s;
	margin-right: 3%;
}

.bottom-right {
	bottom: 12px;
	right: 12px;
	transition: transform .6s ease-in-out;
	animation: toast-in-right .7s;
}

.top-left {
	top: 12px;
	left: 12px;
	transition: transform .6s ease-in;
	animation: toast-in-left .7s;
}

.bottom-left {
	bottom: 12px;
	left: 12px;
	transition: transform .6s ease-in;
	animation: toast-in-left .7s;
}

.notification {
	background: #fff;
	transition: .3s ease;
	position: relative;
	pointer-events: auto;
	overflow: hidden;
	padding: 15px;
	width: 105%;
	max-height: 100px;
	border-radius: 3px 3px 3px 3px;
	box-shadow: 0 0 10px #999;
	color: #000;
	opacity: .9;
	background-position: 15px;
	background-repeat: no-repeat;
	background: #f9f9fa 0% 0% no-repeat padding-box !important;
}

.notification:hover {
	opacity: 1;
	cursor: pointer
}

.notification-message {
	text-align: center;
    height: 18px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
	color:#424750;
}
@media only screen and (max-width: 600px) {
	.notification-message{
		height: 100%;
		white-space: normal;
		text-align: justify;
		padding-right: 3px;
	}
}

.notification-image {
    float: left;
    margin-right: 10px;
	vertical-align: baseline;
}

.notification-image img {
    width: 20px;
    height: 20px;
	top:14px;
	left:18px;
}

.svgToast{
	width: 20px;
	height: 20px;
	fill: #fff;
	background-image: url(../../assets/images/Close.svg);
}
@media only screen and (max-width: 600px) {
.svgToast{
	margin-top:0px;
	}
}

.notification-container button {
	right: -.3em;
	float: right;
	font-weight: 700;
	color: #fff;
	outline: none;
	border: none;
	line-height: 1;
	font-size: 10px;
	padding: 0 !important;
	cursor: pointer;
	background: 0 0;
	justify-content:  center;
	width:20px;
	height: 20px;
}

@keyframes toast-in-right {
	from {
	  transform: translateX(100%);
	  
	}
	to {
	  transform: translateX(0);
	}
}

@keyframes toast-in-left {
	from {
		transform: translateX(-100%);
		
	}
	to {
		transform: translateX(0);
	}
}