@use "../../scss/abstracts/" as *;
@use "sass:math";

.sidebar {
  @include flex(stretch, center);
  flex-direction: column;
  background-color: var(--bgc);
  color: var(--text-color) !important;
  // border: 1px solid blue;
  width: $sideBarOpenWidth;
  height: 100vh;
  position: fixed;
  top: 0;
  transition: all 0.5s ease-in-out;
  z-index: 200;
  overflow-y: auto;
  overflow-x: hidden;

  &.sidebar_close {
    width: 50px;
    @include tablet {
      width: 100%;
    }

    .sidebar__logo {
      opacity: 0;
      visibility: hidden;
      transition: opacity 0.5s ease-in-out, visibility 0.5s ease-in-out, display 0s linear 0.5s;
    }

  }

  @include tablet {
    width: 100vw;
    z-index: 1;
    background-color: var(--secondaryColor);
    color: #ebebeb !important;
  }

  &__logo {
    // @include flex();
    // width: $sideBarLogoHeight;
    text-align: center;
    padding: 0 12px 2rem;

    img {
      --size: 200px;
      width: var(--size);
      height: var(--size);
      object-fit:contain;
    }

    @include tablet {
      padding-top: 1rem;
    }
    @include mobile {
      padding-top: 0.5rem;
    }
  }

  &__menu {
    @include flex(stretch, flex-start);
    flex-direction: column;
    flex-grow: 1;

    &__item {
      @include flex(center, flex-start);
      position: relative;
      margin-bottom: 2rem;
      padding-left: 16px;
      transition: all 0.3s ease-in-out;

      &:hover {
        color: var(--primaryColor);
      }

      &.active {
        font-weight: 700;
        color: var(--primaryColor);
        padding-left: 20px;

        &::before {
          content: "";
          width: 4px;
          height: 100%;
          background-color: var(--primaryColor);
          border-top-right-radius: 3px;
          border-bottom-right-radius: 3px;
          position: absolute;
          left: 0;
          top: 0;
        }
      }

      &__icon {
        @include flex(center, center);
        margin-right: 1rem;

        svg {
          width: $smallIconSize;
          height: $smallIconSize;
        }
      }
      &__txt {
        white-space: nowrap;
      }
    }
  }
}

.logout {
  align-self: flex-end;
}

html[dir="rtl"] {
  .sidebar {
    &__menu {
      &__item {
        padding-left: 0;
        // padding-right: 16px;
        // font-weight: 500;
        font-size: 18px;
        // margin-bottom: 1.5rem;
        &.active {
          padding-right: 4px;
          font-weight: 700;
          &::before {
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
            border-top-left-radius: 3px;
            border-bottom-left-radius: 3px;
            right: 0;
          }
        }

        &__icon {
          margin-left: 1rem;
        }
      }
    }
    &.sidebar_close .sidebar__logo {
      direction: ltr;
      width: 46px;
      margin-right: 10px;
      overflow: hidden;
      @include tablet {
        direction: rtl;
        width: auto;
      }
    }
  }
}
