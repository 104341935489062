@use "./abstracts/" as *;
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
html {
  /* ****Colors**** */
  --primaryColor: #5c2434;
  --secondaryColor: #ae8c9c;
  //light theme
  --bgc: #f2f2f2;
  --card-bgc: #f9f9fa;
  --text-color: #373b42;
  //light theme status
  --approved-bgc: rgba(86, 240, 0, 0.3);
  --approved-textColor: #246500;
  --pending-bgc: rgba(45, 204, 255, 0.3);
  --pending-textColor: #086785;
  --rejected-bgc: rgba(255, 56, 56, 0.3);
  --rejected-textColor: #9d0e0e;

  /* ****Font family**** */
  --fontFamily: "Poppins", "Roboto", sans-serif;
}

html[theme="dark"] {
  //dark theme
  --bgc: #283036;
  --card-bgc: #1e2529;
  --text-color: #ebebeb;

  //light theme status
  --approved-bgc: rgba(66, 171, 8, 0.3);
  --approved-textColor: #d0ffb6;
  --pending-bgc: rgba(45, 204, 255, 0.3);
  --pending-textColor: #a5e9ff;
  --rejected-bgc: rgba(255, 56, 56, 0.3);
  --rejected-textColor: #f2bebe;
}

//reset
*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

img {
  display: block;
  width: 100%;
}

ol,
ul {
  list-style: none;
}

a {
  text-decoration: none;
  color: unset;
}

input,
select {
  font-family: inherit;
  color: inherit;
  font-size: inherit;
  &:focus {
    background-color: transparent;
  }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  // border: 1px solid green;
  // -webkit-text-fill-color: green;
  -webkit-box-shadow: 0 0 0px 1000px transparent inset;
  box-shadow: 0 0 0px 1000px transparent inset;
  transition: background-color 5000s ease-in-out 0s;
}

::placeholder {
  color: #b9b9b9;
}

input[type=file], /* FF, IE7+, chrome (except button) */
input[type=file]::-webkit-file-upload-button {
  /* chromes and blink button */
  cursor: pointer;
}

//general styles
body {
  background-color: var(--bgc);
  color: var(--text-color);
  font-family: var(--fontFamily);
  font-size: $fontSizeBodyLarge;
  line-height: 1.5em;
  overflow-x: hidden;
  @include tablet {
    background-color: var(--secondaryColor);
  }
}

.title {
  font-size: $fontSizeHeadlineSmall;
  font-weight: 700;
  line-height: 2em;
  margin-bottom: 2rem;
}

.subTitle {
  font-size: 18px;
  margin-bottom: 1rem;
}

.status {
  display: inline-block;
  padding: 2px 10px;
  text-align: center;
  font-size: 10px;
  border-radius: $largeBorderRadius;
}
.approved {
  background-color: var(--approved-bgc);
  color: var(--approved-textColor);
}

.pending {
  background-color: var(--pending-bgc);
  color: var(--pending-textColor);
}

.rejected {
  background-color: var(--rejected-bgc);
  color: var(--rejected-textColor);
}

.ltr {
  direction: ltr;
  text-align: left;
}

.rtl {
  font-family: "Roboto", sans-serif ;
  direction: rtl;
  text-align: right;
}

.error {
  color: var(--rejected-textColor);
}

@include tablet {
  .topNav_left_menu_open {
    display: block;
  }

  .topNav_left_menu_close {
    display: none;
  }
}

@include tablet {
  .sidebar__open .main_wrapper {
    height: 100vh;
    overflow: hidden;
    border-top-left-radius: 30px;
    border-bottom-left-radius: 30px;
    transform: scale(0.9) translateX(45%);
    transition: all 0.5s ease-in-out;
    box-shadow: $secondBoxShadow;
  }

  .sidebar__open .topNav_left_menu_open {
    display: none;
  }

  .sidebar__open .topNav_left_menu_close {
    display: block;
  }
}

@include mobile {
  .sidebar__open .main_wrapper {
    transform: scale(0.9) translateX(60%);
  }
}

#overlay-root > div {
  padding: 0;
}

.main_wrapper {
  transform: scale(1) translateX(0);
  // transition: all 0.5s ease-in-out;
}

//----------- not found Page -----------------
.notFound__container {
  font-family: var(--fontFamily);
  @include flex();
  flex-direction: column;
  width: 100%;
  min-height: 100vh;
  @include tablet {
    background-color: var(--bgc);
  }
}

.notFound__container_rtl {
  font-family: "Roboto", sans-serif ;
  min-height: 100vh;
  @include flex();
  flex-direction: column;
  @include tablet {
    background-color: var(--bgc);
  }
}

.notFound__title {
  font-size: 63px;
  margin: 5rem 0 3rem;
  font-family: inherit;
  line-height: 70px;
  &_rtl {
    direction: rtl;
  }

  @include tablet {
    font-size: 36px;
    margin: 3rem;
    line-height: 33px;
  }
  @include mobile {
    font-size: 24px;
    margin: 1rem;
  }
}

.notFound__img {
  max-width: 500px;
  margin: 0 auto;
}

//---------- end of not Found page

html[dir="rtl"] {
  @include tablet {
    .sidebar__open .main_wrapper {
      border-top-right-radius: 30px;
      border-bottom-right-radius: 30px;
      transform: scale(0.9) translateX(-45%);
    }
  }
}

//---------- scrollbar styles

::-webkit-scrollbar {
  width: 0.5rem;
}

::-webkit-scrollbar-track {
  background-color: var(--bgc);
}

::-webkit-scrollbar-thumb {
  background-color: var(--secondaryColor);
  border-radius: 100vw;
}
